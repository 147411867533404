
@use "@angular/material" as mat;
@import "../node_modules/@fdjlss/ngx-org-chart/theming";

$light-primary-text: white;

$custom-primary: (
  50: #0d6986,
  100: #d1c4e9,
  200: #b39ddb,
  300: #9575cd,
  400: #7e57c2,
  500: #673ab7,
  600: #5e35b1,
  700: #512da8,
  800: #4527a0,
  900: #311b92,
  A100: #b388ff,
  A200: #7c4dff,
  A400: #651fff,
  A700: #6200ea,
  contrast: (
    50: $light-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $light-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

@include mat.core();
$custom-typography: mat.define-typography-config(
  $font-family: var(--font-family-style),
  $body-1: mat.define-typography-level(12px, 14px, 400),
  $body-2: mat.define-typography-level(12px, 12px, 400),
  $headline-2: mat.define-typography-level(18px, 18px, 400),
  $subtitle-1: mat.define-typography-level(14px, 14px, 400),
  $subtitle-2: mat.define-typography-level(12px, 12px, 400),
  $button: mat.define-typography-level(12px, 12px, 400),
  $caption: mat.define-typography-level(9px, 9px, 200),
);

$ClientApp-primary: mat.define-palette($custom-primary, 50);
$ClientApp-accent: mat.define-palette(mat.$green-palette);
$ClientApp-warn: mat.define-palette(mat.$red-palette);
$ClientApp-theme: mat.define-light-theme(
  (
    color: (
      primary: $ClientApp-primary,
      accent: $ClientApp-accent,
      warn: $ClientApp-warn,
    ),
    typography: $custom-typography,
  )
);
@include mat.all-component-themes($ClientApp-theme);

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h2 {
    @apply font-bold text-2xl;
  }
}

.btn:focus,
.btn:active:focus,
.btn-link.nav-link:focus,
.form-control:focus,
.form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

@font-face {
  font-family: "rasam";
  src: url("/font/Rasamfont/rasam.woff?x49h15") format("woff"),
    url("/font/Rasamfont/rasam.ttf?x49h15") format("truetype"),
    url("/font/Rasamfont/rasam.svg?x49h15#Rasamfont") format("svg");
  font-display: swap;
}

@font-face {
  font-family: "study";
  src: url("/font/Study/study.woff?v=2") format("woff"),
    url("/font/Study/study.ttf?v=2") format("ttf"),
    url("/font/Study/study.eot?v=2") format("eot"),
    url("/font/Study/study.svg?v=2") format("svg");
  font-display: swap;
}

:root {
  --max-width-container: 1024px;
  --first-color: #267a96;
  --second-color: #0e6986;
}

.Rasamfontclass {
  font-family: rasam !important;
}

.study-font {
  font-family: study;
}

code {
  color: #e01a76;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: "Roboto", sans-serif;
}

html {
  font-size: 12px !important;
}

.mat-mdc-unelevated-button > .mat-icon,
.mat-mdc-outlined-button > .mat-icon {
  flex: 0 0 auto;
  width: 24px !important;
  height: 24px !important;
  font-size: 24px !important;
}

.mat-select-search-panel {
  padding: 0 0 8px 0 !important;
}

.mat-mdc-form-field-bottom-align::before {
  height: 9px !important;
}

.mat-mdc-outlined-button .mat-mdc-button-touch-target {
  height: 30px;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  width: 34px;
  height: 34px;
  padding: 5px;
  line-height: 15px;
}

.without-hint {
  .mat-mdc-form-field-subscript-wrapper {
    height: 0 !important;
  }
}

.mat-mdc-icon-button .mat-mdc-button-touch-target {
  width: 34px !important;
  height: 34px !important;
  line-height: 15px !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined
  .mdc-notched-outline--upgraded
  .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(-23.75px)
    scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.form-dialog-small {
  .dialog-body {
    min-height: 5vh;
  }

  .mat-mdc-dialog-container {
    padding: 0;
    max-height: 90vh;
    min-width: 30vw;
  }

  @media only screen and (max-width: 840px) {
    .mat-mdc-dialog-container {
      max-width: 100vw;
    }
  }

  @media only screen and (max-width: 1280px) and (min-width: 840px) {
    .mat-mdc-dialog-container {
      max-width: 60vw;
    }
  }

  @media only screen and (min-width: 1280px) {
    .mat-mdc-dialog-container {
      max-width: 30vw;
    }
  }
}

.dialog-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 5px 10px;
  border-bottom: 1px solid lightgray;
  background-color: white;

  h2 {
    margin-bottom: 0;
    margin-top: 8px;
  }
}

.dialog-body {
  padding: 10px;
}

.dialog-footer {
  position: sticky;
  bottom: 0;
  padding: 8px 12px;
  // background-color: #88BED7;
  z-index: 1000;
  border-top: 1px solid lightgray;
  background-color: white;
}

.mat-horizontal-content-container {
  padding: 0 24px 12px 24px !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

.tel-select-element .mat-mdc-select-value {
  margin-right: 5px;
}

.max-width {
  max-width: var(--max-width-container) !important;
  margin: auto;
}

.custome-mat-tab {
  [dir="rtl"].mdc-tab-indicator__content--underline {
    border: none;
    background-color: var(--second-color);
    height: 100%;
    border-radius: 40px;
  }

  [dir="rtl"].mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active
    .mdc-tab__text-label {
    color: white;
  }
}

app-header {
  .mat-drawer-container {
    display: unset !important;
    overflow: unset !important;
    top: 0;
  }

  #study-drawer {
    width: var(--mat-sidenav-container-width);
    --mat-sidenav-container-width: 420px;
  }
}

app-main-page {
  .mat-mdc-tab-labels {
    display: flex;
    flex-direction: row;
    margin: auto;
    justify-content: space-evenly;
    width: 100%;
  }

  .mat-mdc-tab {
    max-width: 227px;
  }
}

app-search,
app-search-box {
  .mat-expansion-panel-body {
    padding: 0 24px 16px;
  }
  .mat-expansion-panel-header-title {
    font-weight: 500;
    font-size: 14px;
    color: #5d5d5d;
  }

  .mat-mdc-form-field .mat-mdc-text-field-wrapper {
    background: white;
    color: black;
    outline: none;
    border-radius: 8px;
  }

  .mdc-text-field--outlined .mdc-notched-outline {
    z-index: 1;
    color: black;
    border-radius: 20px;
    outline: none;
  }

  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
      [data-indeterminate="true"]
    )
    ~ .mdc-checkbox__background {
    border-color: white;
    border-radius: 5px;
  }

  .mdc-tab-indicator--active .mdc-tab-indicator__content {
    opacity: 0;
  }

  .mat-mdc-tab-labels {
    width: 100%;
    border-bottom: 1px solid #418ba3;
    padding-bottom: 10px;
  }

  .mdc-tab-indicator--active {
    background-color: #418ba3;
    border-radius: 20px;
    min-width: 150px;
    color: white;
  }

  .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
  .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active
    .mdc-tab__text-label {
    color: white !important;
  }

  .mat-mdc-tab.mdc-tab {
    height: 36px;
  }

  .mat-mdc-tab-body-content {
    padding-top: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 14px;
    justify-content: space-between;
  }

  .mat-mdc-select-trigger {
    padding-left: 8px;
    padding-right: 8px;
  }

  .mat-mdc-select-value {
    min-height: 17px;
  }

  .mdc-notched-outline__leading {
    border-top: none !important;
    border-bottom: none !important;
  }

  .mdc-notched-outline__trailing {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom: none !important;
  }

  .mdc-notched-outline__leading {
    border-left: none !important;
  }

  // search checkBox
  .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
    background: #257590;
  }

  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
      [data-indeterminate="true"]
    )
    ~ .mdc-checkbox__background {
    border-color: white;
    border-radius: 5px !important;
  }

  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
    ~ .mdc-checkbox__background {
    border-color: #257590;
    background-color: #257590;
  }

  .mat-mdc-checkbox
    .mdc-checkbox:hover
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox:hover
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox:hover
    .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
    ~ .mdc-checkbox__background {
    border-color: #257590;
    background-color: #257590;
  }

  .mat-mdc-checkbox
    .mdc-checkbox:not(:disabled):active
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox:not(:disabled):active
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox:not(:disabled):active
    .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
    ~ .mdc-checkbox__background {
    border-color: initial !important;
    background-color: initial !important;
  }

  .mdc-checkbox__background {
    width: 20px;
    height: 20px;
  }

  .mdc-checkbox__native-control:checked
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark-path,
  .mdc-checkbox__native-control:indeterminate
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark-path,
  .mdc-checkbox__native-control[data-indeterminate="true"]
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark-path {
    color: white;
  }

  .mat-expansion-panel:not([class*="mat-elevation-z"]) {
    box-shadow: 0 0 9px #00000014;
  }

  .mat-expansion-panel {
    border-radius: 14px !important;
    background-color: #ffffff;
    border: 1px solid #80808017;
  }

  .filter1 {
    .mat-expansion-panel-header {
      border-radius: 15px;
      background-color: #ededed !important;
      border: 5px solid white;
      transition: all 0.2s ease;

      &:hover {
        background-color: #e4e4e4 !important;
      }

      .mat-expansion-indicator::after {
        border-color: #8e8e8e;
      }
    }

    .mat-mdc-form-field .mat-mdc-text-field-wrapper {
      background: #f3f3f3 !important;
      color: black;
      outline: none;
      border-radius: 8px;
    }

    h3 {
      color: #5f5f5f;
    }
  }

  .program-cart {
    .mat-mdc-form-field .mat-mdc-text-field-wrapper {
      background: #f7f7f7;
      color: black;
      outline: none;
      border-radius: 10px;
      padding: 0;
    }
  }

  .alarm {
    .item-icon {
      transition: all 0.3s ease;
      animation: alarm-icon 1s ease-in 1 forwards;
    }
    .mat-mdc-form-field .mat-mdc-text-field-wrapper {
      transition: all 0.3s ease;
      animation: alarm-input 1s ease-in 1 forwards;
    }
  }

  @keyframes alarm-icon {
    from {
      background-color: #099cc733;
    }
    to {
      background-color: #efefef;
    }
  }
  @keyframes alarm-input {
    from {
      background-color: #099cc733;
    }
    to {
      background-color: #f7f7f7;
    }
  }
  .search-header {
    .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
      background: #b9b9b9;
    }
    .mdc-switch:enabled:hover:not(:focus):not(:active)
      .mdc-switch__track::before {
      background: #ffffff;
    }
    .mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
      background: #f1fbff !important;
    }
    .mdc-switch.mdc-switch--unselected:enabled:hover:not(:focus):not(:active)
      .mdc-switch__handle::after {
      background: #b3b3b3;
    }
    mat-slide-toggle {
      path {
        display: none;
      }
    }
  }
}

app-result-eligibility {
  mat-expansion-panel {
    box-shadow: none !important;
    width: 100%;
    max-width: 100%;
  }
  .mat-expansion-panel-header {
    background: #c3eaff !important;
    height: 30px !important;
    padding: 0 11px 0px 6px;
  }
  .mat-expansion-panel-body {
    padding: 10px;
  }
  .mat-expansion-indicator::after {
    top: -2px !important;
    position: relative;
    border-color: #656565 !important;
  }
}



.top-value-dropdown {
  color: black;
  font-weight: 500;
}
.bottom-value-dropdown {
  color: #8d8d8d;
}
app-dashboard {
  .loginCustom .mdc-notched-outline__leading {
    display: none;
  }
}

app-question-box {
  // question checkBox
  .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
    background: #257590;
  }

  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
      [data-indeterminate="true"]
    )
    ~ .mdc-checkbox__background {
    border-color: white;
    border-radius: 5px !important;
  }

  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
    ~ .mdc-checkbox__background {
    border-color: #257590;
    background-color: #257590;
  }

  .mat-mdc-checkbox
    .mdc-checkbox:hover
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox:hover
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox:hover
    .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
    ~ .mdc-checkbox__background {
    border-color: #257590;
    background-color: #257590;
  }

  .mat-mdc-checkbox
    .mdc-checkbox:not(:disabled):active
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox:not(:disabled):active
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox:not(:disabled):active
    .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
    ~ .mdc-checkbox__background {
    border-color: initial !important;
    background-color: initial !important;
  }

  .mdc-checkbox__background {
    width: 20px;
    height: 20px;
  }

  .mdc-checkbox__native-control:checked
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark-path,
  .mdc-checkbox__native-control:indeterminate
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark-path,
  .mdc-checkbox__native-control[data-indeterminate="true"]
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark-path {
    color: white;
  }

  .mdc-notched-outline__leading {
    display: none;
  }
}

app-single-blog {
  .mat-expansion-panel-header.mat-expanded {
    height: 50px;
    background: #03536c;
  }

  .mat-expansion-panel-header.mat-expanded:focus,
  .mat-expansion-panel-header.mat-expanded:hover {
    background: #03536c !important;
  }

  .mat-expansion-panel-body {
    padding: 16px 24px 16px;
  }

  h2 {
    font-size: 25px;
    font-weight: 600;
    line-height: 4rem;
  }

  ul {
    list-style: circle;
  }

  h3 {
    font-size: 18px;
  }
}

app-blog-category {
  .mat-mdc-text-field-wrapper {
    padding-left: 16px !important;
  }

  .mdc-text-field--outlined
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border-color: #5ca1b9;
  }

  .mdc-text-field--outlined:not(
      .mdc-text-field--disabled
    ).mdc-text-field--focused
    .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(
      .mdc-text-field--disabled
    ).mdc-text-field--focused
    .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(
      .mdc-text-field--disabled
    ).mdc-text-field--focused
    .mdc-notched-outline__trailing {
    border-color: #5ca1b9 !important;
  }

  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border-color: #5ca1b9 !important;
    width: 20px !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
      .mdc-text-field--focused
    ):hover
    .mdc-notched-outline
    .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
      .mdc-text-field--focused
    ):hover
    .mdc-notched-outline
    .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
      .mdc-text-field--focused
    ):hover
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-color: #5ca1b9 !important;
  }

  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border-right: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .mdc-text-field--outlined
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-left: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  [dir="rtl"]
    .mdc-text-field--outlined
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border-top-color: #5ca1b9 !important;
    border-bottom-color: #5ca1b9 !important;
    border-left-color: #5ca1b9 !important;
  }

  [dir="rtl"]
    .mdc-text-field--outlined
    .mdc-notched-outline
    .mdc-notched-outline__leading {
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border-right-color: #5ca1b9 !important;
    width: 20px !important;
  }

  .mdc-text-field--outlined .mdc-notched-outline {
    z-index: 1;
    direction: initial !important;
  }
}

app-login-client {
  .mat-mdc-button[disabled][disabled] {
    background-color: #dddddd;

    span {
      color: grey;
    }

    .mat-icon {
      color: grey;
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }

  .mdc-text-field--outlined .mdc-notched-outline {
    display: none;
  }

  .mat-mdc-button .mdc-button__label,
  .mat-mdc-unelevated-button .mdc-button__label,
  .mat-mdc-raised-button .mdc-button__label,
  .mat-mdc-outlined-button .mdc-button__label {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .mdc-button {
    padding: unset;
  }

  .curve-effect-otp,
  .icon-box-otp {
    &:disabled {
      background-color: #9c9c9c !important;
      color: white;

      .mat-icon {
        color: white;
      }
    }
  }

  .text-otp {
    color: white !important;
  }

  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
      [data-indeterminate="true"]
    )
    ~ .mdc-checkbox__background {
    border-color: white;
    border-radius: 5px !important;
  }

  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
    ~ .mdc-checkbox__background {
    border-color: #257590;
    background-color: #257590;
  }

  .mat-mdc-checkbox
    .mdc-checkbox:hover
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox:hover
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox:hover
    .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
    ~ .mdc-checkbox__background {
    border-color: #257590;
    background-color: #257590;
  }

  .mat-mdc-checkbox
    .mdc-checkbox:not(:disabled):active
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox:not(:disabled):active
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox:not(:disabled):active
    .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
    ~ .mdc-checkbox__background {
    border-color: initial !important;
    background-color: initial !important;
  }

  .mdc-checkbox__background {
    width: 20px;
    height: 20px;
  }

  .mdc-checkbox__native-control:checked
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark-path,
  .mdc-checkbox__native-control:indeterminate
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark-path,
  .mdc-checkbox__native-control[data-indeterminate="true"]
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark-path {
    color: white;
  }
}

app-personal-info {
  .phone-form {
    .mat-mdc-form-field-infix {
      direction: ltr;
    }
  }
}

app-contact-us {
  .mat-mdc-form-field .mat-mdc-text-field-wrapper {
    border: 1.5px solid #0e6986;
    border-radius: 20px;
  }

  .mdc-text-field--outlined .mdc-notched-outline {
    display: none !important;
  }
}

app-login {
  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
      [data-indeterminate="true"]
    )
    ~ .mdc-checkbox__background {
    border-color: white;
    border-radius: 5px !important;
  }

  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
    ~ .mdc-checkbox__background {
    border-color: #257590;
    background-color: #257590;
  }

  .mat-mdc-checkbox
    .mdc-checkbox:hover
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox:hover
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox:hover
    .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
    ~ .mdc-checkbox__background {
    border-color: #257590;
    background-color: #257590;
  }

  .mat-mdc-checkbox
    .mdc-checkbox:not(:disabled):active
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox:not(:disabled):active
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox:not(:disabled):active
    .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
    ~ .mdc-checkbox__background {
    border-color: initial !important;
    background-color: initial !important;
  }

  .mdc-checkbox__background {
    width: 20px;
    height: 20px;
  }

  .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
    height: 40px;
  }
}

app-account-setting {
  .mdc-notched-outline {
    display: none;
  }
}

app-picture {
  .uni-logo {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    object-fit: contain;
  }

  .uni-logo-none {
    width: 65px;
    height: 65px;
    object-fit: contain;
  }

  .search-uni {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: contain;
  }

  .search-uni-none {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }

  .course-logo {
    width: 90px;
    object-fit: contain;
    // width: 100%;
    height: 90px;
    border-radius: 50%;
    // object-fit: cover;
    margin: auto;
  }

  .course-none-logo {
    width: 90px;
    height: 90px;
    border-radius: 10px;
    object-fit: contain;
    margin: auto;
  }

  .single-blog {
    width: 100%;
    height: 330px;
    object-fit: contain;
  }

  .search-blog {
    width: 100%;
    height: 160px;
    border-radius: 20px;
    object-fit: cover;
  }
}

app-university-detail {
  .mat-expansion-panel-spacing {
    margin: unset;
  }

  .mat-expansion-panel-header-description,
  .mat-expansion-indicator::after {
    border-color: #267a96 !important;
  }

  // hide the legend title in university chart
  .legend-title-text {
    display: none !important;
  }

  .mat-mdc-select-trigger {
    width: 95% !important;
    height: 33px !important;
  }

  .mat-mdc-select-value {
    min-height: 17px;
  }
}

app-program-detail {
  .mat-expansion-panel-spacing {
    margin: unset !important;
  }
}

app-profile {
  .family {
    .mat-expansion-panel-body {
      flex-wrap: wrap !important;
    }
  }
}

app-terms-conditions {
  h1 {
    font-weight: 700;
    font-size: 22px;
    width: 100%;
    color: #520b57;
  }

  h3 {
    font-weight: 600;
    font-size: 20px;
  }

  h4 {
    font-weight: 500;
    font-size: 18px;
  }
}

// switch

.mat-mdc-form-field .mdc-notched-outline__notch {
  border-right-style: hidden;
}

.custom-input {
  .mat-mdc-text-field-wrapper {
    background: white;
    border-radius: 20px;// 20px 10px 10px ;
  }
}

.check-confirm {
  .mdc-checkbox__background {
    border-color: white;
    width: 22px;
    height: 22px;
    border-radius: 5px;
  }
}

.login-reg {
  .mdc-button__label {
    width: 100% !important;
  }
}

.paginator {
  .mat-mdc-fab,
  .mat-mdc-mini-fab {
    box-shadow: unset;
  }
}

// .header-desk {
//   position: sticky;
//   top: 0;
//   z-index: 1000;
// }

.profileCustom {
  .mdc-notched-outline__notch {
    border-bottom-color: #5ca1b9 !important;
    border-top-color: #5ca1b9 !important;
  }

  .drag-container {
    border-radius: 20px !important;
    border: 1px solid #5ca1b9 !important;

    & > .over {
      padding: 10px;
    }

    & > div > mat-progress-bar {
      right: unset !important;
      width: calc(100% - 20px);
    }
  }
}

[dir="ltr"] .profileCustom {
  .mdc-text-field--outlined
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border-top-color: #5ca1b9 !important;
    border-bottom-color: #5ca1b9 !important;
    border-right-color: #5ca1b9 !important;
  }

  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border-top-color: #5ca1b9 !important;
    border-bottom-color: #5ca1b9 !important;
    border-left-color: #5ca1b9 !important;
    width: 20px !important;
  }
}

[dir="rtl"] .profileCustom {
  .mdc-text-field--outlined
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border-top-color: #5ca1b9 !important;
    border-bottom-color: #5ca1b9 !important;
    border-right-color: #5ca1b9 !important;
  }

  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border-top-color: #5ca1b9 !important;
    border-bottom-color: #5ca1b9 !important;
    border-left-color: #5ca1b9 !important;
    width: 20px !important;
  }
}

.profileCustom-profile {
  .mat-mdc-text-field-wrapper {
    padding-left: 16px !important;
  }

  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border-right: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .mdc-text-field--outlined
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-left: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  [dir="rtl"]
    .mdc-text-field--outlined
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border-top-color: #5ca1b9 !important;
    border-bottom-color: #5ca1b9 !important;
    border-left-color: #5ca1b9 !important;
  }

  [dir="rtl"]
    .mdc-text-field--outlined
    .mdc-notched-outline
    .mdc-notched-outline__leading {
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border-right-color: #5ca1b9 !important;
    width: 20px !important;
  }
}

.profileCustom-rtl {
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border-right: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .mdc-text-field--outlined
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-left: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.loginCustom {
  .mdc-text-field--outlined
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-radius: 20px;
    border: 1px solid #5ca1b9;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
      .mdc-text-field--focused
    ):hover
    .mdc-notched-outline
    .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
      .mdc-text-field--focused
    ):hover
    .mdc-notched-outline
    .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
      .mdc-text-field--focused
    ):hover
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-color: #5ca1b9 !important;
  }

  [dir="rtl"]
    .mdc-text-field--outlined
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-radius: 20px;
    border: 1px solid #5ca1b9;
  }

  .drag-container {
    border-radius: 20px !important;
    border: 1px solid #5ca1b9 !important;
  }
}

app-question-box {
  .mdc-text-field--outlined
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-radius: 20px;
    border: 2px solid #a4c2d1 !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
      .mdc-text-field--focused
    ):hover
    .mdc-notched-outline
    .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
      .mdc-text-field--focused
    ):hover
    .mdc-notched-outline
    .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
      .mdc-text-field--focused
    ):hover
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border: 2px solid #a4c2d1 !important;
  }

  [dir="rtl"]
    .mdc-text-field--outlined
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-radius: 20px;
    border: 2px solid #a4c2d1 !important;
  }

  .drag-container {
    border-radius: 20px !important;
    border: 2px solid #a4c2d1 !important;
  }
}

.image-shape {
  width: 30px;
  height: 30px;
  border-radius: 20px;
  object-fit: fill;
}

.progress {
  .mdc-linear-progress__bar-inner {
    border-color: #023f52;
  }

  .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
    background-color: white;
  }
}

.country-sec {
  .mdc-form-field > label {
    color: #2e2e2e;
    font-size: 15px;
  }

  .mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:enabled:not(:checked)
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    border-color: #6b6b6b;
  }

  .mat-expansion-panel {
    background-color: #5ca1b9;

    .mat-expansion-panel-header-title {
      color: white;
    }
  }
}

.img-flag {
  border-radius: 20px;
  width: 50px;
  height: 30px;
}

.img-art {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  object-fit: cover;
  // width: 296px;
  width: 100%;
  height: 200px !important;
}

.full {
  width: 100%;
  height: 100%;
}

.no-img {
  object-fit: contain !important;
}

.profileRtl {
  .mdc-notched-outline__notch {
    position: relative !important;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    --mat-mdc-form-field-label-transform: translateY(-23.75px)
      scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
    transform: var(--mat-mdc-form-field-label-transform);
    position: absolute !important;
  }
}

app-video-questionnaire {
  #answer-1,
  #answer-2,
  .answer-btn-svg {
    cursor: pointer;

    tspan {
      cursor: pointer;
    }

    &:hover {
      fill: #dffffc;
    }
  }

  #answer-title-1,
  #answer-title-2 {
    cursor: pointer;
  }
}

.questionnaire-multiselect {
  .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
    .mdc-notched-outline__notch {
    // border-left: 2px solid var(--mdc-outlined-text-field-outline-color) !important;
  }

  .mdc-text-field--outlined
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-radius: 20px;
    border: 1px solid #5ca1b9;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
      .mdc-text-field--focused
    ):hover
    .mdc-notched-outline
    .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
      .mdc-text-field--focused
    ):hover
    .mdc-notched-outline
    .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
      .mdc-text-field--focused
    ):hover
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-color: #5ca1b9 !important;
  }

  [dir="rtl"]
    .mdc-text-field--outlined
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-radius: 20px;
    border: 1px solid #5ca1b9;
  }

  .drag-container {
    border-radius: 20px !important;
    border: 1px solid #5ca1b9 !important;
  }

  .mat-mdc-text-field-wrapper {
    background: white;
    border-radius: 20px;
  }

  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    background-color: #d3e9f1;
  }

  .mat-mdc-chip.mdc-evolution-chip--with-trailing-action
    .mat-mdc-chip-action-label {
    display: flex;
    align-items: center;
    gap: 3px;
  }
}

.meeting-request {
  .radio-card {
    padding: 0px;
    .mdc-form-field.mat-internal-form-field {
      width: 100%;
      height: 100%;
    }
    .mdc-radio {
      display: none;
    }
    label.mdc-label {
      width: 100%;
      display: flex;
      gap: 5px;
      justify-content: center;
      height: 100%;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      padding: 0px;
    }
  }
  //change color of mat radio buttons
  .mdc-radio__outer-circle {
    border-color: #0081a5 !important;
  }

  .mdc-radio__inner-circle {
    border-color: #0081a5 !important;
  }

  .mat-vertical-content-container {
    margin-left: 12px;

    @media (min-width: 1024px) {
      margin-left: 36px;
    }
  }

  //change padding of mat radio button
  .mat-mdc-radio-button .mdc-radio {
    padding: 10px 5px;
  }

  //change mat stepper header
  .mat-step-header .mat-step-icon {
    background-color: #ffffff;
    margin-right: 5px;
    border: 2px gray solid;
  }

  .mat-vertical-stepper-header {
    padding: 15px 0px;

    @media (min-width: 1024px) {
      padding: 15px 24px;
    }
  }

  .mat-step-header:hover:not([aria-disabled]) {
    background-color: transparent;

    .mat-step-icon {
      background-color: transparent;
    }
  }

  .mat-step-header:focus:not([aria-disabled]) {
    background-color: transparent;

    .mat-step-icon {
      background-color: transparent;
    }
  }

  .mat-step-text-label {
    font-size: large;
    color: #099cc7;
  }

  .mat-vertical-content {
    padding: 0 24px 50px 24px;
  }

  .mat-calendar-body-selected {
    border: 1px #ffa9ff solid;
    background: linear-gradient(
      45deg,
      rgba(89, 40, 126, 1) 0%,
      rgba(30, 198, 218, 1) 100%
    );
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled) {
    cursor: pointer;
    .mat-calendar-body-cell-content.mat-focus-indicator:not(
        .mat-calendar-body-selected
      ) {
      border: 1px solid #004d64;
      color: #004d64;
      &:hover {
        border: 1px solid #ffa9ff;
        background: linear-gradient(
          45deg,
          rgb(89, 40, 126) 0%,
          rgb(30, 198, 218) 100%
        );
        color: white;
      }
    }
  }
  .mat-step-icon-selected {
    background-color: rgb(245 245 245);
  }

  //done mode icon
  .mat-step-icon-state-done {
    border-color: #099cc7 !important;
    color: #099cc7;
    border-width: 2px;
  }

  .mat-step-icon-selected:not(.mat-step-icon-state-edit) {
    .mat-step-icon-content {
      position: relative;
      width: 100%;
      height: 100%;
    }

    border: 2px #099cc7 solid !important;
    width: 24px;
    height: 24px;

    &::after {
      content: "";
      position: absolute;
      width: 14px;
      height: 14px;
      background: #099cc7;
      border-radius: 50%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .mat-step-icon-state-edit {
    .mat-step-icon-content {
      position: relative;
      width: 100%;
      height: 100%;
    }

    border: 2px #099cc7 solid !important;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 14px;
      height: 14px;
      transform: translate(-50%, -50%);
      background: #099cc7;
      border-radius: 50%;
    }
  }

  .mat-step-icon-state-selected {
    border-color: #099cc7 !important;
    color: #099cc7;
    border-width: 2px;
  }

  .mat-calendar-meeting {
    border-radius: 30px;
    background-color: #bde9ff;
    font-size: 15px;
  }
}

.meeting-list {
  .mat-expansion-panel-body {
    padding: 0 24px 0px !important;
  }
}

.meeting-payment {
  .mdc-form-field.mat-internal-form-field {
    flex-direction: column-reverse;
    gap: 8px;
  }

  .mdc-form-field > label {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
